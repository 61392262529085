import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import {useStaticQuery, graphql} from 'gatsby'
import {List} from 'antd'

import {menu, item} from './MenuFooter.module.scss'

/**
 * Render the MenuFooter component.
 * @see                       {@link https://ant.design/components/list/}
 * @author                    Content Pilot
   @param  {string}  className Optional class name.
 * @return {Element}           The MenuFooter component.
 */
export default function MenuFooter({className}) {
  const data = useStaticQuery(
    graphql`
      query {
        allWpMenuItem(
          filter: {locations: {eq: FOOTER}, parentDatabaseId: {eq: 0}}
        ) {
          nodes {
            label
            url
            childItems {
              nodes {
                label
                url
              }
            }
          }
        }
      }
    `
  )

  const menuItems = data?.allWpMenuItem?.nodes ?? null

  return (
    <Fragment>
      {menuItems && (
        <List
          className={className ?? menu}
          dataSource={menuItems}
          renderItem={(menuItem) => {
            const {url, label} = menuItem

            return (
              <Fragment>
                <List.Item className={item}>
                  <a href={url} target="_blank" rel="noopener noreferrer">
                    {label}
                  </a>
                </List.Item>
              </Fragment>
            )
          }}
        ></List>
      )}
    </Fragment>
  )
}

MenuFooter.propTypes = {
  className: PropTypes.string
}
