import React from 'react'
import {StaticImage} from 'gatsby-plugin-image'

/**
 * Render the Avatar component.
 * @author  Content Pilot
 * @return {Element} The Avatar component.
 */
export default function Avatar() {
  return (
    <StaticImage
      src="../../../images/deborah-mcmurray.jpg"
      imgStyle={{borderRadius: '50%'}}
      width={90}
      alt="Deborah McMurray"
    />
  )
}
