import React from 'react'
import {StaticImage} from 'gatsby-plugin-image'

import {link, logo, image} from './LogoCp.module.scss'

/**
 * Render the LogoCp component.
 * @author  Content Pilot
 * @return {Element} The LogoCp component.
 */
export default function LogoCp() {
  return (
    <a
      className={link}
      href="https://www.contentpilot.com/"
      rel="noopener noreferrer"
      target="_blank"
      alt="Content Pilot site"
    >
      <StaticImage
        src="../../../images/cp-logo-white.svg"
        className={logo}
        imgClassName={image}
        alt="Content Pilot logo"
      />
    </a>
  )
}
