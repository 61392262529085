import React from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import {StaticImage} from 'gatsby-plugin-image'

import Avatar from 'atoms/Avatar'

import {quote} from './FeaturedQuote.module.scss'

/**
 * Render the FeaturedQuote component.
 * @author  Content Pilot
 * @return {Element} The FeaturedQuote component.
 */
export default function FeaturedQuote() {
  const {wp} = useStaticQuery(graphql`
    query {
      wp {
        fbpSettings {
          quote
        }
      }
    }
  `)

  const quoteText = wp?.fbpSettings?.quote ?? ''

  return (
    <div className={quote}>
      <StaticImage
        src="../../../images/quote.svg"
        width={40}
        alt="Quote icon"
      />

      <p>{quoteText}</p>
      <Avatar />
      <p>
        <strong>Deborah McMurray</strong>
      </p>
      <p>CEO + Strategy Architect</p>
    </div>
  )
}
