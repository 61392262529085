import React from 'react'
import PropTypes from 'prop-types'
import {SlideDown} from 'react-slidedown'

import MenuPrimary from 'molecules/MenuPrimary'

import 'react-slidedown/lib/slidedown.css'
import {slideDown, wrapper} from './SlideMenu.module.scss'

/**
 * Render the SlideMenu component.
 * @see                                {@link https://www.npmjs.com/package/react-slidedown}
 * @author                             Content Pilot
 * @param  {object}   props            The component attributes as props.
 * @param  {Function} props.setVisible The set visible function.
 * @param  {boolean}  props.visible    The visible status.
 * @return {Element}                   The SlideMenu component.
 */
export default function SlideMenu({setVisible, visible}) {
  return (
    <SlideDown className={slideDown}>
      {visible ? (
        <div className={wrapper}>
          <MenuPrimary setVisible={setVisible} />
        </div>
      ) : null}
    </SlideDown>
  )
}

SlideMenu.propTypes = {
  setVisible: PropTypes.func,
  visible: PropTypes.bool
}
