import React, {useState} from 'react'
import {Layout} from 'antd'
import Headroom from 'react-headroom'

import SlideMenu from 'organisms/SlideMenu'
import LogoFbp from 'atoms/LogoFbp'
import ButtonAudit from 'atoms/ButtonAudit'
import ButtonSearch from 'atoms/ButtonSearch'
import ButtonClose from 'atoms/ButtonClose'
import ButtonMenu from 'atoms/ButtonMenu'

import {header} from './Header.module.scss'
import './Header.scss'

const {Header: AntHeader} = Layout

/**
 * Render the Header component.
 * @see     {@link https://ant.design/components/layout/}
 * @author  Content Pilot
 * @return {Element} The Header component.
 */
export default function Header() {
  const [menuVisible, setMenuVisible] = useState(false)

  return (
    <Headroom disableInlineStyles>
      <AntHeader className={header}>
        <LogoFbp />
        <ButtonAudit target="audit-form" />
        {menuVisible ? (
          <ButtonClose onClick={() => setMenuVisible(false)} />
        ) : (
          <ButtonMenu
            onClick={() => {
              setMenuVisible(true)
            }}
          />
        )}
        <ButtonSearch
          onClick={() => {
            setMenuVisible(false)
          }}
        />
      </AntHeader>
      <SlideMenu setVisible={setMenuVisible} visible={menuVisible} />
    </Headroom>
  )
}
