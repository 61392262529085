/**
 * Font Awesome utility to build a Library to reference icons throughout the app.
 * @see {@link https://github.com/FortAwesome/react-fontawesome}
 * @package
 * @author Content Pilot
 * @license   GPL-2.0+
 */

import {library} from '@fortawesome/fontawesome-svg-core'
import {
  faBars,
  faStar,
  faQuoteRight,
  faQuoteLeft,
  faArrowToTop,
  faArrowToBottom,
  faSort,
  faSortUp,
  faSortDown
} from '@fortawesome/pro-solid-svg-icons'
import {faSearch, faPlus, faMinus} from '@fortawesome/pro-regular-svg-icons'
import {
  faTimes,
  faLongArrowDown,
  faLongArrowLeft,
  faLongArrowRight
} from '@fortawesome/pro-light-svg-icons'

library.add(
  faBars,
  faTimes,
  faSearch,
  faPlus,
  faMinus,
  faStar,
  faLongArrowDown,
  faQuoteRight,
  faQuoteLeft,
  faArrowToTop,
  faArrowToBottom,
  faSort,
  faSortUp,
  faSortDown,
  faLongArrowLeft,
  faLongArrowRight
)
