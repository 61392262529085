// extracted by mini-css-extract-plugin
export var bounce = "MenuPrimary-module--bounce--e3665";
export var dotMoveLeft = "MenuPrimary-module--dot-move-left--3e378";
export var dotMoveRight = "MenuPrimary-module--dot-move-right--b4891";
export var firstLevel = "MenuPrimary-module--firstLevel--b62d9";
export var gradient = "MenuPrimary-module--gradient--26762";
export var item = "MenuPrimary-module--item--bc4e7";
export var menu = "MenuPrimary-module--menu--16704";
export var panel = "MenuPrimary-module--panel--f5932";
export var secondLevel = "MenuPrimary-module--secondLevel--35068";
export var separatorWidth = "MenuPrimary-module--separator-width--6bdd1";