import React from 'react'
import {Form, Input, SubmitButton} from 'formik-antd'
import {useFormikContext, Formik} from 'formik'
import * as Yup from 'yup'

import {form, error, successful, failure} from './FormAudit.module.scss'

/**
 * Handle submit event.
 * @param  {Array}    values            The form values
 * @param  {object}   options           The form options
 * @param  {Function} options.resetForm The resetForm function
 * @param  {Function} options.setStatus The setStatus function
 * @return {void}
 */
async function handleSubmit(values, {resetForm, setStatus}) {
  const response = await fetch(process.env.GATSBY_GETFORM_URL, {
    method: 'POST',
    body: JSON.stringify(values, null, 2),
    headers: {
      'Content-Type': 'application/json'
    }
  })

  resetForm({})

  if (response.ok) {
    setStatus({
      send: true,
      message: 'Thanks for your message, we will get in touch with you shortly.'
    })
  } else {
    setStatus({send: false, message: 'Something was wrong please try later'})
  }

  return response
}

const schema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Requires between 2 and 50 characters')
    .max(50, 'Requires between 2 and 50 characters')
    .required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  website: Yup.string().required('Required'),
  comments: Yup.string()
    .min(2, 'Requires between 2 and 200 characters')
    .max(200, 'Requires between 2 and 200 characters')
})

/**
 * Render the InnerForm component.
 * @return {Element} The InnerForm component.
 */
function InnerForm() {
  const {errors, touched, status} = useFormikContext()

  return (
    <Form className={form}>
      <div>
        <Input
          id="first-input"
          name="name"
          placeholder="Name *"
          aria-label="Name"
        />
        {errors.name && touched.name && (
          <span className={error}>{errors.name}</span>
        )}
      </div>
      <div>
        <Input name="email" placeholder="Email *" aria-label="Email" />
        {errors.email && touched.email && (
          <span className={error}>{errors.email}</span>
        )}
      </div>
      <div>
        <Input
          name="website"
          placeholder="Website URL *"
          aria-label="Website URL"
        />
        {errors.website && touched.website && (
          <span className={error}>{errors.website}</span>
        )}
      </div>
      <div>
        <Input
          name="comments"
          placeholder="Tell us your concerns/comments"
          aria-label="Tell us your concerns/comments"
        />
        {errors.comments && touched.comments && (
          <span className={error}>{errors.comments}</span>
        )}
      </div>
      <SubmitButton>Submit</SubmitButton>
      {typeof status?.send === 'boolean' && (
        <span className={status.send ? successful : failure}>
          {status.message}
        </span>
      )}
    </Form>
  )
}

/**
 * Render the FormAudit component.
 * @see     {@link https://formik.org/docs/overview}
 * @author  Content Pilot
 * @return {Element} The FormAudit component.
 */
export default function FormAudit() {
  return (
    <Formik
      initialValues={{
        name: '',
        email: '',
        website: '',
        comments: ''
      }}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      <InnerForm />
    </Formik>
  )
}
