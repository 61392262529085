import React from 'react'
import {Link} from 'gatsby'
import {StaticImage} from 'gatsby-plugin-image'

import {link, logo, image} from './LogoFbp.module.scss'

/**
 * Render the LogoFbp component.
 * @author  Content Pilot
 * @return {Element} The LogoFbp component.
 */
export default function LogoFbp() {
  return (
    <Link className={link} to="/">
      <StaticImage
        layout="constrained"
        src="../../../images/fbp-logo.svg"
        imgClassName={image}
        className={logo}
        alt="Ten Foundational Best Practices logo"
      />
    </Link>
  )
}
