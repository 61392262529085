import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import {useStaticQuery, graphql} from 'gatsby'
import {useLocation} from '@reach/router'
import {SkipNavLink, SkipNavContent} from '@reach/skip-nav'

import Header from '../Header'
import Footer from '../Footer'
import Audit from '../Audit'
import Cookie from 'molecules/Cookie'
import Transition from 'utils/transition'

/**
 * Render the Layout component.
 * @see                    {@link https://ant.design/components/layout/}
 * @author                 Content Pilot
 * @param  {Node}    children Child component(s) to render.
 * @return {Element}          The Layout component.
 */
export default function Layout({children}) {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const location = useLocation()

  return (
    <Fragment>
      <SkipNavLink />
      <Header siteTitle={data.site.siteMetadata.title} />
      <Transition location={location}>{children}</Transition>
      <SkipNavContent />
      <Audit />
      <Footer />
      <Cookie />
    </Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}
