import React from 'react'
import PropTypes from 'prop-types'
import {Button} from 'antd'
import ScrollableAnchor from './../ScrolleableAnchor'

import {button} from './ButtonAudit.module.scss'

/**
 * Handle the click event.
 * @param {object} e Event.
 */
function handleClick(e) {
  e.preventDefault()
  const element = document.getElementById('first-input')
  element && element.focus({preventScroll: true})
}

/**
 * Render the ButtonAudit component.
 * @see                          {@link https://ant.design/components/button/}
 * @author                       Content Pilot
 * @param  {object}  props        The component attributes as props.
 * @param  {string}  props.target The button target.
 * @param  {string}  props.offset The button offset.
 * @return {Element}              The ButtonAudit component.
 */
export default function ButtonAudit({target, offset}) {
  return (
    <Button
      type="link"
      className={button}
      aria-label="Audit button"
      onClick={(e) => handleClick(e)}
    >
      <ScrollableAnchor
        target={target}
        offset={offset}
        ariaLabel="Scroll audit button"
      >
        Request an Audit
      </ScrollableAnchor>
    </Button>
  )
}

ButtonAudit.prototype = {
  target: PropTypes.string.isRequired,
  offset: PropTypes.string
}
