import React from 'react'
import PropTypes from 'prop-types'
import {
  TransitionGroup,
  Transition as ReactTransition
} from 'react-transition-group'

const timeout = 500

const getTransitionStyles = {
  entering: {
    position: `absolute`,
    opacity: 0
  },
  entered: {
    transition: `opacity ${timeout}ms ease-in-out`,
    opacity: 1
  },
  exiting: {
    transition: `opacity ${timeout}ms ease-in-out`,
    opacity: 0
  }
}

/**
 * Render the Transition component.
 * @see                   {@link https://reactcommunity.org/react-transition-group/transition-group}
 * @author                Content Pilot
 * @param  {object}  props The component attributes as props.
 * @return {Element}       The Transition component.
 */
export default function Transition(props) {
  const {children, location} = props

  return (
    <TransitionGroup>
      <ReactTransition
        key={location.pathname}
        timeout={{
          enter: timeout,
          exit: timeout
        }}
      >
        {(status) => (
          <div
            style={{
              ...getTransitionStyles[status]
            }}
          >
            {children}
          </div>
        )}
      </ReactTransition>
    </TransitionGroup>
  )
}

Transition.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object
}
