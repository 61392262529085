import React from 'react'
import {Layout} from 'antd'

import LogoCp from 'atoms/LogoCp'
import MenuFooter from 'molecules/MenuFooter'
import Address from 'atoms/Address'
import LinkWebsite from 'atoms/LinkWebsite'

import {footer} from './Footer.module.scss'

const {Footer: AntFooter} = Layout

/**
 * Render the Footer component.
 * @see     {@link https://ant.design/components/layout/}
 * @author  Content Pilot
 * @return {Element} The Footer component.
 */
export default function Footer() {
  return (
    <AntFooter className={footer}>
      <LogoCp />
      <MenuFooter />
      <Address />
      <LinkWebsite />
    </AntFooter>
  )
}
