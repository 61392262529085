import React from 'react'

import {link} from './LinkWebsite.module.scss'

/**
 * Render the LinkWebsite component.
 * @author  Content Pilot
 * @return {Element} The LinkWebsite component.
 */
export default function LinkWebsite() {
  return (
    <div className={link}>
      <a
        href="https://www.contentpilot.com/"
        rel="noopener noreferrer"
        target="_blank"
      >
        contentpilot.com
      </a>
    </div>
  )
}
