import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import {useStaticQuery, graphql, Link} from 'gatsby'
import {List, Collapse} from 'antd'

const {Panel} = Collapse

import {
  menu,
  item,
  firstLevel,
  secondLevel,
  panel
} from './MenuPrimary.module.scss'

/**
 * Render the MenuPrimary component.
 * @see                       {@link https://ant.design/components/list/}
 * @author                    Content Pilot
 * @param  {string}  className Optional class name.
 * @return {Element}           The MenuPrimary component.
 */
export default function MenuPrimary({setVisible, className}) {
  const data = useStaticQuery(
    graphql`
      query {
        allWpMenuItem(
          filter: {locations: {eq: PRIMARY}, parentDatabaseId: {eq: 0}}
        ) {
          nodes {
            label
            path
            childItems {
              nodes {
                label
                path
              }
            }
          }
        }
      }
    `
  )

  const menuItems = data?.allWpMenuItem?.nodes ?? null

  return (
    <Fragment>
      {menuItems && (
        <List
          className={className ?? menu}
          dataSource={menuItems}
          renderItem={(menuItem) => {
            const {path, label} = menuItem
            const subItems = menuItem?.childItems?.nodes ?? []

            return (
              <Fragment>
                {subItems.length === 0 ? (
                  <List.Item className={item}>
                    <Link
                      to={path}
                      className={firstLevel}
                      onClick={() => setVisible(false)}
                    >
                      {label}
                    </Link>
                  </List.Item>
                ) : (
                  <List.Item className={item}>
                    <Collapse ghost showArrow={false}>
                      <Panel header={label} className={panel}>
                        {subItems.map((subItem, key) => (
                          <Link
                            to={subItem.path}
                            key={key}
                            className={secondLevel}
                            onClick={() => setVisible(false)}
                          >
                            {subItem.label}
                          </Link>
                        ))}
                      </Panel>
                    </Collapse>
                  </List.Item>
                )}
              </Fragment>
            )
          }}
        ></List>
      )}
    </Fragment>
  )
}

MenuPrimary.propTypes = {
  setVisible: PropTypes.func,
  className: PropTypes.string
}
