import React from 'react'
import PropTypes from 'prop-types'
import {Button} from 'antd'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import {button} from './ButtonClose.module.scss'

/**
 * Render the ButtonClose component.
 * @see                             {@link https://ant.design/components/button/}
 * @author                          Content Pilot
 * @param  {object}   props         The component attributes as props.
 * @param  {Function} props.onClick The onclick handle.
 * @return {Element}                The ButtonClose component.
 */
export default function ButtonClose({onClick}) {
  return (
    <Button
      type="link"
      onClick={onClick}
      className={button}
      aria-label="Close button"
    >
      <FontAwesomeIcon icon={['fal', 'times']} aria-label="Close icon" />
    </Button>
  )
}

ButtonClose.prototype = {
  onClick: PropTypes.func
}
