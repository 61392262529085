import React from 'react'
import {Link} from 'gatsby'
import CookieConsent from 'react-cookie-consent'

import {container, content, accept, decline} from './Cookie.module.scss'

/**
 * Render the Cookie component.
 * @see     {@link https://www.npmjs.com/package/react-cookie-consent/}
 * @author  Content Pilot
 * @return {Element} The Cookie component.
 */
export default function Cookie() {
  return (
    <CookieConsent
      sameSite="strict"
      location="bottom"
      buttonText="Accept"
      declineButtonText="Decline"
      enableDeclineButton
      cookieName="gatsby-gdpr-google-analytics" // Do not modify it.
      disableStyles
      flipButtons
      disableButtonStyles
      containerClasses={container}
      contentClasses={content}
      buttonClasses={`${accept} ant-btn`}
      declineButtonClasses={`${decline} ant-btn`}
    >
      <p>
        This site uses cookies to store information on your computer. Some of
        these cookies are essential, while others help us to improve your
        experience by providing insights into how the site is being used. Click
        Accept to continue using the site with recommended settings, or Decline
        to disable optional cookies. For more detailed information on the
        cookies we use, please review our
        <Link to="/privacy-policy">Privacy Policy</Link>
      </p>
    </CookieConsent>
  )
}
