import React from 'react'
import PropTypes from 'prop-types'
import AnchorLink from 'react-anchor-link-smooth-scroll'

/**
 * Render the ButtonAudit component.
 * @see                             {@link https://www.npmjs.com/package/react-anchor-link-smooth-scroll}
 * @author                          Content Pilot
 * @param  {object}  props           The component attributes as props.
 * @param  {string}  props.target    The anchor target.
 * @param  {string}  props.offset    The anchor offset.
 * @param  {Node}    props.children  The anchor children.
 * @param  {string}  props.className The anchor className.
 * @param  {string}  props.ariaLabel The anchor ariaLabel.
 * @return {Element}                 The ButtonAudit component.
 */
export default function ScrollableAnchor({
  target,
  offset = 0,
  children,
  className,
  ariaLabel
}) {
  return (
    <AnchorLink
      offset={offset}
      href={`#${target}`}
      className={className}
      aria-label={ariaLabel}
    >
      {children}
    </AnchorLink>
  )
}

ScrollableAnchor.prototype = {
  target: PropTypes.string.isRequired,
  offset: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  ariaLabel: PropTypes.string
}
