import React from 'react'
import PropTypes from 'prop-types'
import {Button} from 'antd'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import {button} from './ButtonSearch.module.scss'

/**
 * Render the ButtonSearch component.
 * @author                          Content Pilot
 * @param  {object}   props         The component attributes as props.
 * @param  {Function} props.onClick The onclick handle.
 * @return {Element}                The ButtonSearch component.
 */
export default function ButtonSearch({onClick}) {
  return (
    <Button
      type="link"
      onClick={onClick}
      href="/search"
      className={button}
      aria-label="Search button"
    >
      <FontAwesomeIcon icon={['far', 'search']} aria-label="Search icon" />
    </Button>
  )
}

ButtonSearch.prototype = {
  onClick: PropTypes.func
}
