import React from 'react'
import {StaticImage} from 'gatsby-plugin-image'
import {useStaticQuery, graphql} from 'gatsby'
import {Typography} from 'antd'

import FeaturedQuote from 'molecules/FeaturedQuote'
import FormAudit from 'molecules/FormAudit'

import {wrapper, icon, title, intro} from './Audit.module.scss'

const {Title} = Typography

/**
 * Render the Audit component.
 * @see     {@link https://formik.org/docs/overview}
 * @author  Content Pilot
 * @return {Element} The Audit component.
 */
export default function Audit() {
  const {wp} = useStaticQuery(graphql`
    query {
      wp {
        fbpSettings {
          title
          intro
        }
      }
    }
  `)

  const sectionTitle = wp?.fbpSettings?.title ?? ''
  const sectionIntro = wp?.fbpSettings?.intro ?? ''

  return (
    <section id="audit-form" className={wrapper} aria-label="Request an Audit">
      <div className={icon}>
        <StaticImage
          src="../../../images/color-bars.png"
          width={60}
          alt="Colors bar icon"
        />
      </div>
      <Title className={title} level={2}>
        {sectionTitle}
      </Title>
      <p className={intro}>{sectionIntro}</p>
      <FormAudit />
      <FeaturedQuote />
    </section>
  )
}
