import React from 'react'

import {address} from './Address.module.scss'

/**
 * Render the Address component.
 * @author  Content Pilot
 * @return {Element} The Address component.
 */
export default function Address() {
  return (
    <div className={address}>
      <div>
        <p>6600 LBJ Freeway, Suite 165</p>
        <p>Dallas, TX 75240</p>
        <a href="tel:972.234.4030">
          <span>972.234.4030</span>
        </a>
      </div>
    </div>
  )
}
